.loadingModal {
    height: 5rem!important;
    width: 5rem!important;
    margin-left: auto !important;
    margin-right: auto!important;
}

.loadingSpinner {
    width: 3rem!important;
    height: 3rem!important;
}